import { handleSubmitForm } from './handleSubmitForm';
import { handleToTop } from './toTop';

(function( $ ) {
  window.addEventListener('DOMContentLoaded', function () {
    console.log('JS is working - remove it when it was tested');
    handleSubmitForm();
    handleToTop();

    let params = new window.URLSearchParams(window.location.search);
    if(params.get('apply') === 'yes') {
      $('#chooseFile').addClass('show');
    }


    $('.popup-gallery').magnificPopup({
      delegate: "a[rel='lightbox']",
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('title');
        }
      }
    });

    $('.wp-block-gallery').magnificPopup({
      delegate: ".wp-block-image img",
      type: 'image',
      tLoading: 'Loading image #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      callbacks: {
        elementParse: function(item) {
          item.src = item.el.attr('src')
        }
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function(item) {
          return item.el.attr('alt');
        }
      }
    });

    $('#menu-button').on('click', function () {
      $('.nav-wrapper').toggle();
      $('#menu-button').toggleClass('close');
    });

    $(window).resize(function(){
      if(window.innerWidth > 992) {
        $('.nav-wrapper').show();
        $('#menu-button').addClass('close');
      } else {
        $('.nav-wrapper').hide();
        $('#menu-button').removeClass('close');
      }
    });
  });
})( jQuery );

function setCookie(cname, cvalue, exdays) {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires="+currentDate.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const cookies = document.cookie.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}
