import isEmail from 'validator/es/lib/isEmail';

const validation = (name, email, phone, message) => {
  const result = {
    errors: {},
    valid: true,
  };

  if (name.value.length < 1) {
    result.errors.name = 'To pole jest obowiązkowe';
  } else {
    delete result.errors.name;
  }
  if (phone.value.length < 1) {
    result.errors.phone = 'To pole jest obowiązkowe';
  } else {
    delete result.errors.phone;
  }
  if (message.value.length < 1) {
    result.errors.message = 'To pole jest obowiązkowe';
  } else {
    delete result.errors.message;
  }
  if (email.value.length < 1) {
    result.errors.email = 'To pole jest obowiązkowe';
  } else if (!isEmail(email.value)) {
    result.errors.email = 'Niepoprawny email';
  } else {
    delete result.errors.email;
  }
  result.valid = Object.keys(result.errors).length === 0

  return result;
};

const showFieldError = (element, message) => {
  hideFieldError(element);
  if (message) {
    let error = '';
    error = document.createElement('div');
    error.classList.add('form-error');
    error.innerText = message;
    element.after(error);
  }
}

const hideFieldError = (element) => {
  element.parentElement.querySelector('.form-error')?.remove();
}

const hideAllFieldError = (formElement) => {
  formElement.querySelectorAll('.form-error').forEach(element => element.remove());
}

const showFieldsErrors = (errors) => {
  const name = document.querySelector("#contact-form-name");
  const email = document.querySelector("#contact-form-email");
  const phone = document.querySelector("#contact-form-phone");
  const message = document.querySelector("#contact-form-msg");

  showFieldError(name, errors.name);
  showFieldError(email, errors.email);
  showFieldError(phone, errors.phone);
  showFieldError(message, errors.message);
}

const toBase64 = file => new Promise((resolve, reject) => {
  if (!file) {
    resolve(null)
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const handleSubmitForm = () => {
  const form = document.querySelector("#contact-form");
  const name = document.querySelector("#contact-form-name");
  const phone = document.querySelector("#contact-form-phone");
  const email = document.querySelector("#contact-form-email");
  const message = document.querySelector("#contact-form-msg");
  const uploadFile = document.querySelector("#contact-form-uploadFile");
  const buttonSubmit = document.querySelector('#contact-form_send');
  const buttonLoader = document.querySelector('#contact-form_send .loader');
  const buttonSpan = document.querySelector('#contact-form_send span');

  if (form) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();
      const validationResult = validation(name, email, phone, message);

      if (1) {
        hideAllFieldError(form)
        buttonSubmit.disabled = true;
        buttonLoader.classList.add('show')
        buttonSpan.classList.add('hide')

        const file = uploadFile.files[0]
        toBase64(file).then((base64file) => {
          const uploadFileData = base64file ? {
            name: file.name,
            type: file.type,
            content: base64file
          } : null

          fetch('/wp-content/api/send-mail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: name.value,
              email: email.value,
              phone: phone.value,
              message: message.value,
              uploadFile: uploadFileData,
            }),
          }).then((res) => {
            if (res.status >= 200 && res.status < 300) {
              form.reset();
              // TODO Here you can add your code to inform user that mail was sent
              console.log('Mail sent');

              buttonSubmit.disabled = false;
              buttonLoader.classList.remove('show')
              buttonSpan.classList.remove('hide')

              let messageSuccess = '';
              const button = document.querySelector("#contact-form_send");
              messageSuccess = document.createElement('div');
              messageSuccess.classList.add('message-success');
              messageSuccess.innerHTML = `Wiadomość została wysłana poprawnie`;


              button.before(messageSuccess);
              setTimeout(function(){ messageSuccess.remove(); }, 5000);
            } else {
              // TODO Here you can add your code to inform user that mail was not sent
              console.error('Network error during sending email');
              waiting.classList.remove('show')
              let messageError = '';
              const button = document.querySelector("#contact-form_send");
              messageError = document.createElement('div');
              messageError.classList.add('message-error');
              messageError.innerText = 'Przepraszamy! Coś poszło nie tak... Spróbuj ponownie lub skontaktuj się z nami telefonicznie.';
              button.before(messageError);
            }
          }).catch((err) => {
            // TODO Here you can add your code to inform user that mail was not sent
            console.error('Error during sending email', err);
            let messageError = '';
            const button = document.querySelector("#contact-form_send");
            messageError = document.createElement('div');
            messageError.classList.add('message-error');
            messageError.innerText = 'Przepraszamy! Coś poszło nie tak... Spróbuj ponownie lub skontaktuj się z nami telefonicznie.';
            button.before(messageError);
            buttonSubmit.disabled = false;
            buttonLoader.classList.remove('show')
            buttonSpan.classList.remove('hide')
          });
        })
      }

      showFieldsErrors(validationResult.errors);
    });
  }
};
